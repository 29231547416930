import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class PermissionServ {
    baseUrl: string;
    constructor(private http: HttpClient) {
        this.baseUrl = 'http://smartkioskadminapi.gopermit.co.uk';
     //   this.baseUrl='http://localhost:52216';
    }
    public GetRoles() {
        return this.http.get(this.baseUrl + "/api/services/app/Permission/GetRoles");
    }
    public GetSites() {
        return this.http.get(this.baseUrl + "/api/services/app/Permission/GetSites");
    }
    public GetScreens(RoleId) {
        return this.http.get(this.baseUrl + "/api/services/app/Permission/GetScreens?RoleId=" + RoleId);
    }
    public GetSiteAlerts(SiteId) {
        return this.http.get(this.baseUrl + "/api/services/app/Permission/GetSiteAlerts?SiteId=" + SiteId);
    }
    public GetRoleScreens() {
        return this.http.get(this.baseUrl + "/api/services/app/Permission/GetRoleScreens");
    }
    public SavePermissionData(data) {
        return this.http.post(this.baseUrl + "/api/services/app/Permission/SavePermissionData", data);
    }
    public SaveAlertData(data) {
        return this.http.post(this.baseUrl + "/api/services/app/Permission/SaveSiteAlerts", data);
    }
    public GetModulesScreens() {
        return this.http.get(this.baseUrl + "/api/services/app/Permission/GetModuleScreens");
    }
}